<template>
    <div>

        <b-card class="invoice-preview-card">
            <div class="d-flex justify-content-between flex-wrap">
                <h2 class="my-auto">Configuration des clés D'API FedaPay & KKiaPay<br /></h2>
            </div>
        </b-card>
        <b-card>
            <b-card-header class="p-0">
                <h4>Ajout des clés</h4>
            </b-card-header>
            <hr />
            <b-card-body>
                <b-row>
                    <b-col md="6">
                        <b-form-group label="FedaPay" label-cols-md="12" label-for="fedapay">
                            <b-form-input id="fedapay" v-model="form.fedapay_key"
                                placeholder="Veuillez saisir la clé fedapay" type="text" />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="KKiaPay" label-cols-md="12" label-for="kkiapay">
                            <b-form-input id="kkiapay" v-model="form.kkiapay_key"
                                placeholder="Veuillez saisir la clé kkiapay" type="text" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <div class="d-flex flex-row-reverse mt-1">

                <b-button variant="primary" @click="updateConfigurations()">
                    <feather-icon icon="SaveIcon" v-if="loadingUpdateConfig == false" /><b-spinner
                        v-if="loadingUpdateConfig == true" small /> &nbsp; Enregistrer
                </b-button>
            </div>
        </b-card>
    </div>
</template>
  
<script>
import {
    BTable,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BModal,
    VBModal,
    BAlert,
    BCardText,
    BFormRadioGroup,
    BSpinner,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Ripple from "vue-ripple-directive";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { VueEditor } from "vue2-editor";

// ---------------------------- store modules and vuex utilities
import appConfigurationStroreModule from "@/store/app-configuration";

import {
    registerStoreModule,
    // unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
    components: {
        quillEditor,
        BTable,
        BCard,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BFormCheckbox,
        BModal,
        VBModal,
        BAlert,
        BSpinner,
        BCardText,
        BFormRadioGroup,
        BCardHeader,
        BTabs,
        BTab,
        BCardBody,
        VueEditor,
        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            configs: [
                {
                    id: null,
                    code: "config.fedapay.key",
                    value: "",
                },
                {
                    id: null,
                    code: "config.kkiapay.key",
                    value: "",
                },
            ],
            loadingUpdateConfig: false,
            form: {
                fedapay_key: "",
                kkiapay_key: "",
            },

            selectedOption: "",
            snowOption: {
                theme: "snow",
            },
            editorOption: {
                theme: "snow",
                // modules: {
                //   toolbar: '#quill-toolbar',
                // },
                placeholder: "",
            },

        };
    },
    computed: {},
    watch: {
        $route: {
            immediate: true,
            handler(val, old) {
                this.loadDataAppConfiguration();
            },
        },
    },
    setup() {
        const requiredStoreModules = [
            { path: "app-configurations", module: appConfigurationStroreModule },
        ];
        // Register module
        registerStoreModule(requiredStoreModules);

        return { requiredStoreModules };
    },
    mounted() { },
    methods: {
        ...mapMutations("app-configurations", {
            action_setter_passForgotAccess: "setter_passForgotAccess",
        }),
        // import actions
        ...mapActions("app-configurations", {
            action_getAppConfiguration: "getAppConfiguration",
            action_searchAppConfiguration: "searchAppConfiguration",
            action_updateAppConfiguration: "updateAppConfiguration",
        }),
        // -----------------------------------------------------------------------
        async loadDataAppConfiguration() {
            this.configs.forEach(async (config, index) => {
                this.action_searchAppConfiguration({ code: config.code })
                    .then((response) => {
                        if (response.status === 200) {
                            this.configs[index].value = response.data.data[0].value;
                            this.configs[index].id = response.data.data[0].id;
                            if (index === 0)
                                this.form.fedapay_key = this.configs[index].value;
                            if (index === 1) this.form.kkiapay_key = this.configs[index].value;
                        }

                    })
                    .catch((err) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: err.message || "Une erreur est survenue",
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    });
            });
        },

        async updateConfigurations() {
            this.loadingUpdateConfig = true;
            //   this.configs.map((e) => (e.value = e.value.toString()));
            let haveError = false;
            for (let index = 0; index < this.configs.length; index++) {
                const config = this.configs[index];
                if (index === 0) config.value = this.form.fedapay_key;
                if (index === 1) config.value = this.form.kkiapay_key;

                //  config.value.toString();
                const { id, code, value } = config;
                // eslint-disable-next-line no-await-in-loop
                await this.action_updateAppConfiguration({ id, data: { code, value } })
                    .then((response) => {
                        // console.log('response: ', response)
                    })
                    // eslint-disable-next-line no-loop-func
                    .catch((err) => {
                        console.log("err: ", err);
                        haveError = true;
                    });
            }
            this.loadingUpdateConfig = false;
            if (!haveError) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Opération réussie",
                        icon: "ThumbsUpIcon",
                        variant: "success",
                    },
                });
            }
        },

    },
};

</script>
<style scoped>
::v-deep .nav-pills {
    margin-bottom: 0px;
    margin-left: 1rem;
    margin-right: 1rem;
}

::v-deep .nav-pills .nav-link {
    color: white !important;
}

::v-deep .nav-pills .nav-link.active {
    color: #000000 !important;
}
</style>